/* VARIABLES */
@import 'variables';

/* BOOTSTRAP */
@import '~bootstrap/scss/bootstrap';

/* COTI STYLE */
@font-face {
  font-family: 'saudon';
  src:url('/fonts/saudon.woff2') format('woff2'),
      url('/fonts/saudon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  background: center / cover no-repeat url('/images/bg-immotop-min.jpg') fixed;
}

.main {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background: rgba(255, 255, 255, .85);
  box-shadow: 0 3px 9px #333;
}

/* TYPO */
h1,
h2,
h3 {
  font-weight: 400;
}

h1,
h2 {
  color: #800;
  font-family: 'saudon', cursive;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.4rem;
}

a,
a:link a:visited {
  color: #ec971f;
}

a:hover {
  text-decoration: none;
}

/* MENU - NAV */
#cotiNav {
  padding: 0;
  background: #343434;
}

.navbar-brand {
  max-width: 190px;
  opacity: .9;
}

.navbar-brand:hover {
  opacity: 1;
}

.nav-link:hover {
  color: #ff0;
}
.nav-item:focus,
.nav-item:hover {
  background: #ffa500;
  color: #800;
}

.dropdown-menu {
  background: #ffa500;
}
.dropdown-item {
  color: #800;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background: #800;
  color: #ffa500;
}
.dropdown-divider {
  border-top: 1px solid #800;
}

#cotiNav.sticky-top {
  transition: all .4s;
}

@media (min-width: 768px) {
  #cotiNav {
    padding: 0;
    background: #800;
    box-shadow: 0 2px 6px #333;
  }
  .navbar-brand {
    max-width: 250px;
  }
  .navbar-nav {
    min-height: 62px;
  }
  .nav-link {
    padding-top: 22px;
    padding-bottom: 16px;
  }
  .nav-item:focus,
  .nav-item:hover {
    background: linear-gradient(#800, #ffa500);
  }
  .dropdown-menu {
    margin-top: 0;
    padding-top: 2px;
    border: 0;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 2px 6px #333;
  }
  .dropdown:hover>.dropdown-menu {
    display: block;
  }
}

@media (min-width:768px) and (max-width:840px) {
  .navbar-brand img {
    padding-top: 6px;
    width: 190px;
  }
}

/* CARD */
.card:hover {
  background: #fff !important;
  box-shadow: 0 2px 6px #333;
}

.card-body h2,
.card-body h3 {
  margin: 5px 0 0;
  color: #800;
  font-family: 'saudon', cursive;
}
.card-body .alert img,
.card-body .alert img {
  width: 12px;
}

/* HOME PAGE */
.homepage .card {
  margin: 0 4px 8px !important;
  border-radius: 0;
}
.homepage .card_img_1,
.homepage .card_img_2 {
  margin: -1px;
  height: 250px;
  overflow: hidden;
}

.homepage .card_img_1 img,
.homepage .card_img_2 img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  transition: .3s;
}

.homepage .card-body .alert {
  padding: .4rem;
  font-size: .75rem;
}

.row-one h2,
.row-two h2,
.row-one h3,
.row-two h3 {
  text-align: center;
}
.row-one .card-body h2,
.row-two .card-body h2 {
  font-size: 1.2rem;
}
.row-one .card-body h3,
.row-two .card-body h3 {
  font-size: 1.4rem;
}
.row-one .card-body p,
.row-two .card-body p {
  margin-bottom: 0;
  font-size: .9rem;
}

@media (min-width: 576px) {
  .row-one .card,
  .row-two .card {
    min-width: 47%;
    max-width: 50%;
  }
  .card-columns {
    column-count: 2;
    column-gap: .5rem;
  }
  .homepage .card_img_1,
  .homepage .card_img_2 {
    height: 155px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .row-one .alert img {
    display: none;
  }
}

@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
  }
  .homepage .card_img_1 {
    height: 140px;
  }
  .homepage .card_img_2 {
    height: 210px;
  }
}

@media (min-width: 992px) {
  .row-one .card {
    min-width: 31%;
    max-width: 33%;
  }
  .row-two .card {
    min-width: 23%;
    max-width: 25%;
  }
  .card-columns {
    column-count: 4;
  }
  .homepage .card_img_1,
  .homepage .card_img_2 {
    height: 150px;
  }
  .homepage .card_img_1 img:hover,
  .homepage .card_img_2 img:hover {
    filter: saturate(2);
    transform: scale(1.05);
  }
}

@media (min-width: 1200px) {
  .homepage .card_img_1,
  .homepage .card_img_2 {
    height: 175px;
  }
}
/* FORM */
.style_form {
  background: #fff url('/images/patterns/cream-pixels.png');
}

/* BUTTON */
.btn-warning {
  color: #fff;
  background: #f0ad4e;
  border-color: #eea236;
  :hover {
    color: #fff;
    background: #ec971f;
    border-color: #d58512;
  }
}

.back-to-previous {
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 20px;
  display: none;
  color: #850409 !important;
  z-index: 9999;
}
.back-to-previous:hover {
  background: #850409;
  border-color: #fff;
  color: #fff !important;
}

/* CAROUSEL */
.carousel-inner {
  max-height: 400px;
}

.carousel-caption {
  bottom: 25px;
  padding: 0;
  color: #fff;
  text-shadow: 0 2px 3px rgba(0,0,0,.6);
  background: rgba(0,0,0,.5);
}

.carousel-indicators {
  margin-bottom: 0;
}

/* LE PIED */
footer {
  margin-top: 25px;
  padding: 15px 0;
  background: #800;
  color: #ffa500;
  h3 {
    font-size: 1.15rem;
    font-weight: 700;
  }
  a:hover {
    color: #ffff4a;
  }
}

/* GO TOP */
#goTop {
  position: fixed;
  bottom: 1rem;
  right: 0;
  height: 64px;
  width: 64px;
  display: none;
  background-image: url('/images/gotop.png');
  transition: all .3s ease-in-out;
  z-index: 1050;
  :hover {
    background-image: url('/images/gotop-hover.png');
  }
}

/* PAGINATION */
.page-item.active .page-link {
	background-color: #ec971f;
	border-color: #d58512;
}

.page-item.disabled .page-link {
	background-color: #fff;
  border-color: #d58512;
  color: #ccc;
  cursor: not-allowed;
}
.page-link {
  border-color: #d58512;
  color: #d58512;
  :hover {
    background-color: #f0ad4e;
    border-color: #d58512;
    color: #fff;
  }
}

/* SVG ICONS */
.icon {
  display: inline-block;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0;
  vertical-align: -0.2em;
}

/* FONT SIZE */
.fs3 { font-size: 3rem; }
.fs6 { font-size: 6rem; }
